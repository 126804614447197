.show-ledger {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.show-ledger h2 {
  margin-bottom: 20px;
  text-align: center;
}

.show-ledger input {
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.show-ledger-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.show-ledger-table th, 
.show-ledger-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.show-ledger-table th {
  background-color: #f4f4f4;
}

.show-ledger-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.show-ledger-table tr:hover {
  background-color: #f1f1f1;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.pagination button,
.pagination select,
.pagination input {
  padding: 8px;
  font-size: 16px;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.ledheading{
  text-align:center;
}