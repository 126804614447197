body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}

.additional-receipt-container{
  min-width: 600px;  
  max-width: 1200px;
  margin: auto;
}

.para-heading{
  height: 30px;
  background: #f2f2f2;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  width: 100%;
  display: grid;
  align-items: center;
  margin-bottom: 20px;
  padding: 0px;
  text-align: left !important;
}

.additionalreceiptform {
  padding: 20px;
  border-radius: 8px;
  font-family: Arial, sans-serif;
  /* background-color: #fff; */
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
}

.row1, .row2, .row3, .row4, .row5, .row6, .row7 {
  display: flex;
  flex-wrap: wrap;
  gap: 0px;
  margin-bottom: 15px;
  justify-content: space-between;
  align-items: center;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #555;
}

.row1 label, .row2 label, .row3 label, .row4 label, .row5 label {
  width: 150px;
}

.row6 {
  display: flex;
  justify-content: space-between;
}

.addClientInput {
  width: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.addClientInputselect {
  width: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.addClientInput:focus {
  border-color: #007bff;
  outline: none;
}

.addclientbutton {
  width: 210px;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.addclientbutton:hover {
  background-color: #0056b3;
}

.red {
  color: red;
  margin: 0px;
}

.add-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.dynamic-rows-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0px;
}

.dynamic-rows-table th, .dynamic-rows-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.dynamic-rows-table th {
  background-color: #f4f4f4;
}

.dynamic-rows-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.dynamic-rows-table tr:hover {
  background-color: #f1f1f1;
}

.dynamic-rows-table td button {
  background-color: #e74c3c;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
  border-radius: 3px;
}

.freightrowinput {
  width: 200px;
}

.addrowrupeeinput {
  width: 100%;
}

.selectDiv {
  display: flex;
  gap: 21px;
  align-items: center;
}

.newrowdata {
  display: flex;
  gap: 17px;
  align-items: center;
}

.headingaddrec {
  text-align: center;
  font-size: 18px;
}

.addClientInputselectfreight {
  width: 200px;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .row1, .row2, .row3, .row4, .row5, .row6, .row7 {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 15px;
    justify-content: space-between;
    align-items: center;
  }
  
  .addClientInput, .addClientInputselect {
      width: 70%;
  }
  
  .dynamic-rows-table {
      font-size: 14px;
  }

  .selectDiv, .newrowdata {
      flex-direction: column;
      gap: 10px;
  }
}

@media (max-width: 768px) {
  .additional-receipt-container {
      min-width: auto;
      padding: 0 10px;
  }

  .addclientbutton {
      width: 100%;
      padding: 15px;
  }
  
  .dynamic-rows-table {
      font-size: 12px;
  }
}

@media (max-width: 576px) {
  label {
      font-size: 14px;
  }

  .addClientInput, .addClientInputselect {
      width: 100%;
      padding: 8px;
  }

  .addclientbutton {
      font-size: 0.9em;
  }
}
.savebuttonbill{
  width: 210px;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}
.btnbill{
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 5px;
}