.packing-list-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.packing-list-heading {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
}

.packing-list-gr-selector {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.packing-list-gr-selector label {
    margin-right: 10px;
    font-size: 16px;
}

.packing-list-gr-selector select {
    width: 200px;
    padding: 8px;
    font-size: 16px;
    border-radius: 3px;
}

.packing-list-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.packing-list-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.packing-list-form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.packing-list-form-group label {
    font-size: 14px;
    margin-bottom: 6px;
}

.packing-list-input {
    padding: 8px;
    font-size: 14px;
    width: 100%;
    border: 0.5px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
}

.packing-list-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    font-size: 14px;
}

.packing-list-table th{
    background-color: #dddddd;
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
    font-size: medium;
}

.packing-list-table td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
}

.packing-list-btn {
    padding: 6px 12px;
    cursor: pointer;
    font-size: 14px;
    margin: 4px;
    border-radius: 3px;
}

.packing-list-add {
    background-color: #4CAF50;
    color: white;
    border: none;
}

.packing-list-delete {
    background-color: #f44336;
    color: white;
    border: none;
}
.packing-list-submit-btn{
    width: 210px;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
}

.packing-list-submit-btn:hover {
    background-color: #0056b3;
}
.packing-list-submit-btn:disabled{
    background-color: grey;
    cursor: no-drop;
}