.container{
    margin: auto;   
    border: 1px solid black;
    width: 950px;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 0px;
    padding-bottom: 30px;
}
    
.rsno{
border: none !important;
color: red;
font-size: 17px;    
}
.cashspan{
    font-size: 17px;
    font-weight: bold;
}

.contactinfo{
    margin-bottom:8px !important;
}
.cashpara{
    line-height: 25px;
    font-weight: 400;
}
.overlayTabs{
    
    display: flex;
    gap: 5px;
}
.input-cashreceipt {
    border-radius: 6px;
    padding: 7px;

}
.headercompany{
    margin-bottom: 5px;
    color: red;
}
.upperdata,.branchcashreceipt,.tocashreceipt,.fromcashreceipt,.companyHeading,.addressdetails,.cashreceiptdate,.receiptno{
    margin: 0px;
}

.h3-cashreceipt {
    margin: 10px 0px;
}

.body-cashreceipt {
    font-family: Arial, sans-serif;
    margin: 15px;
    background-color: #f4f4f4;
}


.top-cashreceipt {
    display: grid;
    grid-template-columns: 20% 55% !important;
    margin-bottom: 10px;
}

.head-cashreceipt {
    text-align: center;
}


.row1-cashreceipt {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.row1-cashreceipt input {
    width: 200px;
    padding: 10px;
    font-size: large;
}

.manager{
text-align: end;
}

.svg-biltycashrepreviewceipt{
    POSITION: relative;
    LEFT: 33PX;
    TOP: 6PX;
}
.rupee,.for{
    margin: 0px;
}
.rupee {
    display: flex;
    position: relative;
    right: 20px;
    gap: 5px;}
.rsinput{
    text-align: center;
    width: 180px !important;
}


.submit-button:disabled{
    cursor:no-drop;
    background-color: grey;
}

.submit-button {
    border: none; /* Remove borders */
    color: white; /* White text */
    padding: 15px 32px; /* Some padding */
    text-align: center; /* Centered text */
    text-decoration: none; /* Remove underline */
    display: inline-block; /* Make the button inline-block */
    font-size: 16px; /* Increase font size */
    margin: 20px 2px; /* Some margin */
    cursor: pointer; /* Pointer/hand icon */
    border-radius: 12px; /* Rounded corners */
    transition-duration: 0.4s; /* Animation */
    color: black;
    width: 200px;
}

.submit-button:hover {
    background-color: white; /* White background on hover */
    color: grey; /* Black text on hover */
    border: 0.5px solid;
}
.cashno{
    width: 133px !important;
}
.frominput{
    width: 210px !important;

}
.dateinput{
    width: 214px !important;
}
.branchinput{
    width: 200px !important;
}
.row-cashreceipt-1{
    display: flex;
    justify-content: space-between;
}
.rsinputwords{
    width: 500px;
}
.authorisedsignature{
    text-align: end;
    margin-bottom: 25px;
}