.packing-preview-container{
    margin: auto;   
    border: 1px solid black;
    width: 620px;
    padding: 10px;
    }
.rsno{
border: none !important;
color: red;
font-size: 17px;    
}
.packingspan{
    font-size: 17px;
    font-weight: bold;
}

.contactinfo{
    margin-bottom:8px !important;
}
.headercompany{
    margin-bottom: 5px;
    color: red;
}
.upperdata,.branchcashreceipt,.tocashreceipt,.fromcashreceipt,.companyHeading,.addressdetails,.cashreceiptdate,.receiptno{
    margin: 0px;
}

.h3-cashreceipt {
    margin: 10px 0px;
}

.body-cashreceipt {
    font-family: Arial, sans-serif;
    margin: 15px;
    background-color: #f4f4f4;
}


.top-packinglist {
    display: grid;
    grid-template-columns: auto auto auto;
    margin-bottom: 10px;
    
}

.head-packinglist {
    text-align: center;
}


.row1-packinglist {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.row2-packinglist {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.row1-packinglist input {
    width: 200px;
    padding: 10px;
    font-size: large;
}



.packing-submit-button:disabled{
    cursor:no-drop;
    background-color: grey;
}

.packing-submit-button {
    border: 0.5px solid; /* Remove borders */
    color: white; /* White text */
    padding: 15px 32px; /* Some padding */
    text-align: center; /* Centered text */
    text-decoration: none; /* Remove underline */
    display: inline-block; /* Make the button inline-block */
    font-size: 16px; /* Increase font size */
    margin: 20px 2px; /* Some margin */
    cursor: pointer; /* Pointer/hand icon */
    border-radius: 12px; /* Rounded corners */
    transition-duration: 0.4s; /* Animation */
    color: black;
    width: 200px;
}

.packing-submit-button:hover {
    background-color: white; /* White background on hover */
    color: grey; /* Black text on hover */
    border: 0.5px solid;
}
.cashno{
    width: 133px !important;
}
.frominput{
    width: 210px !important;

}
.dateinput{
    width: 214px !important;
}
.branchinput{
    width: 200px !important;
}
.row-cashreceipt-1{
    display: flex;
    justify-content: space-between;
}
.rsinputwords{
    width: 500px;
}
.packing-preview-table {
    margin-top: 8px;
    width: 100%;
    line-height: 24px;
    text-align: left;
    border-collapse: collapse;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}

.packing-preview-table th {
    padding: 8px;
    background-color: #dddddd;
    border: 1px solid #ddd;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    color: black;
}
.packing-preview-table td {
    padding: 4px;
    border: 1px solid #ddd;
    font-size: 16px;
    text-align: center;
    color: black;
}
@media print {

    .no-print{
        display: none;
    }
    }