body {
    font-family: Arial, sans-serif;
    background-color: white;
}

.preview-invoice-box {
    padding: 1px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.preview-invoice-box table {
    width: 100%;
    line-height: 15px;
    text-align: left;
    border-collapse: collapse;
}

.preview-invoice-box table td {
    padding: 5px;
    vertical-align: top;
    border: 1px solid #ddd;
    font-size: 12px;
}

.preview-invoice-box table th {
    background-color: #f2f2f2;
    border: 1px solid #ddd;
    font-weight: 400 !important;
    text-align: center;
    color: black;
    padding-top: 10px;
    padding-bottom: 10px;
}

.preview-invoice-box table tr td:nth-child(2) {
    text-align: left;
}

.preview-title {
    font-size: 30px;
    line-height: 24px;
    color: #333;
    text-align: center;
    font-weight: 400 !important;
    margin-top: 5px;
}

.preview-title p {
    margin: 0px;
    font-size: 20px;
}

.preview-information table {
    padding-bottom: 40px;
}

.preview-heading {
    background: #f2f2f2;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
}

.preview-para-heading {
    background: #f2f2f2;
    border-bottom: 1px solid #ddd;
    font-weight: 500;
    width: 100%;
    display: block;
    margin: 0px;
    padding: 0px;
    text-align: center;
}

.preview-details {
    padding-bottom: 20px;
}

.preview-item {
    border-bottom: 1px solid #f2f2f2;
}

.preview-item.last {
    border-bottom: none;
}

.preview-total {
    border-top: 2px solid #f2f2f2;
    font-weight: bold;
}

.preview-signature {
    margin-top: 40px;
}

.preview-preformatted {
    font-family: monospace;
    font-size: 12px;
}

.preview-textarea {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    resize: none;
}

.preview-abc {
    width: 100%;
    height: 100%; 
    box-sizing: border-box; 
    resize: none; 
    font-size: 15px;
}

.preview-mop {
    margin: 1px;
}

.preview-ltp {
    line-height: 57px;
}

.preview-rs {
    width: 65px;
}

.preview-dem {
    width: 42px;
}
.cont{
    display: flex;
    justify-content: space-between;
    width: 1500px;
    margin-top: 15px;
}
.selectcopy{
    padding: 3px;
    border: none;
    color: red;
    background-color: #f2f2f2;
}
textarea{
resize: none;
}
.savebuttonpreview {
    float: left;
    border: 0.5px solid;
    color: white; 
    padding: 9px 15px; 
    text-align: center; 
    text-decoration: none; 
    display: inline-block; 
    font-size: 16px; 
    margin: 20px 2px;
    cursor: pointer; 
    border-radius: 12px; 
    transition-duration: 0.4s; 
    color: black;
    width: 100px;
}

.savebuttonpreview:hover {
    background-color: white; /* White background on hover */
    color: grey; /* Black text on hover */
    border: 0.5px solid black;
}

/* preview boxes width  */
.width-row1-col1,.width-row2-col1{
    width: 32%;
}
.width-row2-col2{
    width: 50%;
}
.width-row1-col2,.width-row1-col3{
    width: 25%;
}
.tablepackwidth{
    width: 15%;
}
.descripwidth{
width:28%
}
.actualwidth{
    width: 7%;
}
.charges{
    width: 5%;}
    .ftl{
        width: 8%;
    }
    .amtpaid{
        width: 13%;
    }
    .rate{
        width:6%;
    }
@media print {
    select {
      -webkit-appearance: none; 
      -moz-appearance: none;    
      appearance: none;         
      background-image: none;   
    }
  
    /* For Internet Explorer */
    select::-ms-expand {
      display: none;
    }
.no-print{
    display: none;
}
}
.previewbtn{
    float: right;
    border: 0.5px solid; 
    color: white; 
    padding: 9px 15px; 
    text-align: center; 
    text-decoration: none; 
    font-size: 16px;
    margin: 20px 2px; 
    cursor: pointer; 
    border-radius: 12px; 
    transition-duration: 0.4s; 
    color: black;
    width: 100px;
}
p,.payerr{
    font-size: 15px;
    font-weight: 400;
}
span{
    font-size: 15px;
    font-weight: 500;
}
.preview-title-name{
    color: red;
    font-size: 34px;
}
.preview-red{
    color: red;
}
.inked-seal {
    font-family: 'Georgia', serif;
    font-weight: bold;
    color: red; /* Dark ink color */
    padding: 10px 20px;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 0px;
}
