/* Overlay.css */

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);
  z-index: 1000;
  /* Start with the overlay hidden off the screen */
}

.overlay-content {
  width: 600px;
  height: 96%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: scroll;
  scrollbar-width: none;
}

.overlay.show {
  transform: translateX(0);
  /* Slide the overlay in */
}

.close-button {
  /* position: fixed; */
  align-self: flex-end;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
.my-form-additionalinfo {
  display: flex;
  flex-direction: column;
}

.section-heading-additionalinfo {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.form-section-additionalinfo {
  margin-bottom: 10px;
}

.grid-layout-additionalinfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.label-additionalinfo {
  display: flex;
  flex-direction: column;
  font-size:medium;
}

.input-additionalinfo,
.textarea-additionalinfo {
width: 100%;
padding: 8px;
margin-top: 5px;
border: 1px solid #ccc;
border-radius: 4px;
font-size:small;
}

.textarea-additionalinfo {
  resize: vertical;
}

.button-additionalinfo {
  padding: 10px;
  color: black;
  border: 0.5px solid;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.button-additionalinfo:hover {
  background-color: #747474;
  color: aliceblue;
}
.button-container {
  display: flex;
  justify-content: flex-end; /* Aligns buttons to the right side */
  gap: 10px; /* Adjust the gap between buttons as needed */
  margin-bottom: 5px;
}
.additionalinfo-para-heading{
  height: 30px;
  background: #f2f2f2;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  width: 100%;
  display: grid;
  align-items: center;
  padding: 0px;
  text-align: left !important;
}