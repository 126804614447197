.client-dashboard {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.top-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1e0d4f;
  color: white;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 1024px) {
  .top-navbar {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .top-navbar {
    flex-direction: column;
    align-items: flex-start;
  }
}

.search-bar input {
  padding: 5px;
}

.logout-button {
  padding: 5px 10px;
  background-color: #1e0d4f;
  color: white;
  border: none;
  cursor: pointer;
}

.main-content {
  display: flex;
  flex: 1;
}

.vertical-navbar {
  width: 270px;
  background-color: #1e0d4f;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
}

.vertical-navbar__menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.vertical-navbar__item {
  padding: 10px;
}

.vertical-navbar__link,
.vertical-navbar__sub-link {
  color: white;
  text-decoration: none;
  display: block;
}

.vertical-navbar__link:hover,
.vertical-navbar__sub-link:hover {
  background-color: #030061;
}

.vertical-navbar__main-category {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vertical-navbar__sub-category {
  list-style-type: none;
  margin: 0;
}


.content-area {
  flex: 1;
  padding: 10px;
  background-color: #f0f0f0;
}

.profile-menu {
  position: relative;
}

.profile-btn {
  padding: 5px 10px;
  background-color: #1e0d4f;
  color: white;
  border: none;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #1e0d4f;
  border: none;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-item {
  padding: 10px;
  color: white;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #555;
}

.home-screen {
 display: flex;
 justify-content: space-between;
 
 text-align: center;
  margin-top: 20px;
}
.biltyhead{
  text-align: center;
  
}