/* Tabs.css */
.admin-show-tabs-container {
    width: 80%;
    margin: 20px auto;
    font-family: Arial, sans-serif;
  }
  
  h2 {
    margin-bottom: 20px;
  }
  
  .admin-show-tabs-tabs {
    display: flex;
    border-bottom: 2px solid #ccc;
    margin-bottom: 20px;
  }
  
  .admin-show-tabs-tab {
    padding: 10px 20px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 5px 5px 0 0;
    margin-right: 5px;
  }
  
  .admin-show-tabs-tab.admin-show-tabs-active {
    background-color: #1e0d4f;
    color: white;
    border-color: #1e0d4f;
  }
  
  .admin-show-tabs-tab:not(.admin-show-tabs-active):hover {
    background-color: #f0f0f0;
  }
  
  .admin-show-tabs-tab-content {
    /* border: 1px solid #ccc;
    padding: 20px; */
    border-radius: 5px;
  }
  