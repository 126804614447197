.show-bilty {
    max-width: 1000px;
    width: 1000px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .show-bilty h2 {
    margin-bottom: 20px;
    text-align: center;
  }
  .show-bilty input{
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  }
  .error {
    color: red;
    margin-bottom: 10px;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  ul li {
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  ul li:last-child {
    border-bottom: none;
  }
  .show-bilty-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .show-bilty-table td, .show-bilty-table th {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .show-bilty-table tr:nth-child(even){background-color: #f2f2f2;}
  
  .show-bilty-table tr:hover {background-color: #ddd;}
  
  .show-bilty-table th {
    background-color: #f4f4f4;
  }

  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination button,
  .pagination select,
  .pagination input {
    padding: 8px;
    font-size: 16px;
  }
  
  .pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  