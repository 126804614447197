
label{
    font-weight: 400 !important;
}
.overlayTabs{    
    display: flex;
    gap: 5px;
}
.input-cashreceipt {
    border-radius: 6px;
    padding: 7px;
}
.body-cashreceipt {
    font-family: Arial, sans-serif;
    margin: 15px;
    background-color: #f4f4f4;
}
.top-cashreceipt {
    display: grid;
    grid-template-columns: 18% 66% !important;
    margin-bottom: 15px;
    
}

.row1-cashreceipt {
    display: flex;
    justify-content: space-between;
}

.row1-cashreceipt input {
    width: 200px;
    padding: 10px;
    font-size: large;
}


.row7-cashreceipt input {
    width: 300px;
    padding: 10px;
    font-size: large;
    text-align: center;
}

.manager{
text-align: end;
}

.svg-cashreceipt {
    POSITION: relative;
    LEFT: 33PX;
    TOP: 5PX;
}
.createcash-submit-button{
    width: 210px;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px;
}

.createcash-submit-button:hover {
    background-color: #0056b3;
}
.createcash-submit-button:disabled{
    background-color: grey;
    cursor: no-drop;
}
.dateinput{
    width: 214px !important;
}
.row{
    gap: 20px;
    margin-bottom: 20px;

}
.rowinput{
    width: 74.3% !important;
}
.rowrupeeinput{
    width: 93.1% !important;
}
.cash-rec-header{
    margin: 10px;
    font-size: 22px;
}

.dynamic-table table{
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0px;
  }
  
  .dynamic-table th, .dynamic-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .dynamic-table th {
    background-color: #f4f4f4;
  }
  
  .dynamic tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .dynamic-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .dynamic-table td button {
    background-color: #e74c3c;
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;    
    cursor: pointer;
    border-radius: 3px;
  }
  .selectDivCashreceipt{
    display: flex;
    align-items: center;
    gap: 29px;
  }

  .cashrupee{
    position: relative;
    right: 20px;
    margin-bottom: 15px;

  }