.additional-container{
    margin: auto;   
    border: 1px solid black;
    width: 950px;
    padding: 10px;
    }
    .rsno{
border-bottom: 2px dotted black;
color: red;
font-size: 17px;    
}
.cashspan{
    font-size: 17px;
    font-weight: bold;
}

.additionalcontactinfo{
    margin-bottom:8px !important;
}
p{
    font-weight: 400;
}
.overlayTabs{
    
    display: flex;
    gap: 5px;
}
.input-additionalcashreceipt {
    border-radius: 6px;
    padding: 7px;

}
.additionalheadercompany{
    margin-bottom: 5px;
    color: red;
}
.additionalupperdata,.branchcashreceipt,.tocashreceipt,.fromcashreceipt,.companyHeading,.addressdetails,.cashreceiptdate,.receiptno{
    margin: 0px;
}

.h3-additionalcashreceipt {
    margin: 10px 0px;
}

.body-additionalcashreceipt {
    font-family: Arial, sans-serif;
    margin: 15px;
    background-color: #f4f4f4;
}


.top-additionalcashreceipt {
    display: grid;
    grid-template-columns: 20% 55% !important;
    margin-bottom: 10px;
    
}

.head-additionalcashreceipt {
    text-align: center;
}


.row1-additionalcashreceipt {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.row1-additionalcashreceipt input {
    width: 200px;
    padding: 10px;
    font-size: large;
}

.manager{
text-align: end;
}

.rupee,.for{
    margin: 0px;
}
.rupee{
    position: relative;
    right: 20px;
}
.rsinput{
    text-align: center;
    width: 180px !important;
}


.submit-button:disabled{
    cursor:no-drop;
    background-color: grey;
}

.submit-button {
    border: 0.5px solid; /* Remove borders */
    color: white; /* White text */
    padding: 15px 32px; /* Some padding */
    text-align: center; /* Centered text */
    text-decoration: none; /* Remove underline */
    display: inline-block; /* Make the button inline-block */
    font-size: 16px; /* Increase font size */
    margin: 20px 2px; /* Some margin */
    cursor: pointer; /* Pointer/hand icon */
    border-radius: 12px; /* Rounded corners */
    transition-duration: 0.4s; /* Animation */
    color: black;
    width: 200px;
}

.submit-button:hover {
    background-color: white; /* White background on hover */
    color: grey; /* Black text on hover */
    border: 0.5px solid;
}
.cashno{
    width: 133px !important;
}
.frominput{
    width: 210px !important;

}
.dateinput{
    width: 214px !important;
}
.branchinput{
    width: 200px !important;
}
.row-additionalcashreceipt-1{
    display: flex;
    justify-content: space-between;
}
.additionalPreviewlist{
    display: flex;
    justify-content: space-between;
}
.additionalPreviewlistitem{
    display: grid;
    justify-items: center;
}
@media print {

.no-print{
    display: none ;
}
}
.additional-preview-table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  .additional-preview-th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
    font-weight: 400;
  }
  .additional-preview-td{
    border: 1px solid #dddddd;
    text-align: right;
    padding: 8px;
  }

