.set-pin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  background-color: #f5f5f5; /* Light background for contrast */
}

.set-pin-container form {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.set-pin-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.form-group {
  width: 100%;
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

.form-group input {
  width: 90%;
  padding: 8px 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
}

.form-group input:focus {
  border-color: #007bff; /* Blue border on focus */
}

/* PIN container to hold input and eye icon */
.pin-container {
  position: relative;
  width: 100%;
}

/* Adjust input to accommodate the eye icon */
.pin-input {
  width: 100%;
  padding-right: 40px; /* Space for the eye icon */
  padding: 8px 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
}

.pin-input:focus {
  border-color: #007bff; /* Blue border on focus */
}

/* Eye icon for toggling PIN visibility */
.toggle-pin {
  position: absolute;
  right: 18px; /* Position the icon inside the input field */
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #555;
}

/* Button styling */
.setpin-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.setpin-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.error-message {
  color: #ff4d4d; /* Red for error */
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}

.success-message {
  color: #28a745; /* Green for success */
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}
