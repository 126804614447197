.ledger-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.ledger-header{
    text-align: center;
    margin: 3px;
}
.ledger-form-group {
    margin-bottom: 15px;
}

.ledger-form-label {
    display: block;
    margin-bottom: 5px;
    font-weight: 400;
    color: #333;
}

.ledger-form-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
}

.ledger-form-select {
    width: 100%;

    /* border: 1px solid #ddd; */
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
}

.ledger-form-input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
}

.ledger-form-select:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
}

.ledger-form-button {
    display: block;
    width: 30%;
    padding: 10px 0;
    font-size: 18px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.ledger-form-button:hover {
    background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .ledger-form {
        padding: 15px;
    }

    .ledger-form-label,
    .ledger-form-input,
    .ledger-form-select,
    .ledger-form-button {
        font-size: 14px;
    }
}
.back-button {
    position: relative;
    top: 10px;
    left: 10px;
    font-size: 1.5rem;
    cursor: pointer;
    color: #007bff; /* Adjust color as needed */
  }
  
  .back-button:hover {
    color: #0056b3; /* Adjust color as needed */
  }
  